<script>
  // libs:
  import Vue from 'vue'
  // components:
  import Vuetify, {
    VApp as App,
    VAppBar as AppBar,
    VMain as Main,
    VFooter as Footer,
    VImg as Img,
    VToolbarTitle as ToolbarTitle,
    VAvatar as Avatar,
    VThemeProvider as ThemeProvider,
    VRow as Row,
    VCol as Col,
    VIcon as Icon,
    VResponsive as Div,
    VContainer as Container,
    VParallax as Parallax,
    VCard as Card,
    VSpacer as Spacer,
    VCardTitle as CardTitle,
    VCardText as CardText,
    VCardAction as CardAction,
    VDivider as Divider,
    VButton as Button
  } from 'vuetify/lib'

  export default {
    data () {
      return {
        name: 'asd'
      }
    },
    vuetify: function() {
      Vue.use(Vuetify)
      return new Vuetify()
    }(),
    render () {
      const APPBAR_HEIGHT = 100

      return <App class='app'>
        <AppBar class='app-bar'
          height={APPBAR_HEIGHT}
          fixed={true}
        >
          <Avatar class=''
            color='#a72028'
            rounded='9'
          >
            <Img class=''
              src={require('/../src/img/logo.outless.png')}
              size='small'
              max-width='95%'
              max-height='96%'
            />
          </Avatar>
          <ToolbarTitle class=''>
            <h2>&nbsp;脑洞英语课堂</h2>
          </ToolbarTitle>
        </AppBar>
        <Main class=''
          style={{
          marginTop: `${APPBAR_HEIGHT}px`
          }}
        >
          <Img class=''
            min-height={`calc(100vh - ${APPBAR_HEIGHT}px)`}
            src={require('./res/deskchair.jpeg')}
            // src='https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80'
          >
            <ThemeProvider class='d-flex'>
              <Button class=''>
                <Icon type='plus'/>
              </Button>
              <Row class=''
                align='center'
              >
                <Col class=''
                  cols={12}
                  tag='h1'
                  align='center'
                  style='
                  color: white;
                  text-shadow: 0pt 1pt 2pt black;
                  margin-top: 33%;
                  '
                >英语教师的互助成长教研中心
                </Col>
              </Row>
            </ThemeProvider>
          </Img>
          <Div class=''>
            <Row class=''
              align='center'
            >
              <Col class=''
              cols={12}
                tag='h2'
                align='center'
              >ABOUT US
              </Col>
              <Col class=''
                cols={5.5}
              />
              <Col class='double-divider-col'
                cols={1}
                align='center'
              >
                <Divider class='ttl-divider'/>
                <Divider class='ttl-divider'/>
              </Col>
              <Col class=''
                cols={5.5}
              />
            </Row>
          </Div>
          <Div class='max-auto mb-12'
            max-width={640}
            tag='big'
            align='center'
            style='
            margin-left: auto;
            margin-right: auto;
            '
          >Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam
          </Div>
          <Div class=''
            align='center'
          >{
            ([
              require(`/../src/img/members/1.png`),
              require(`/../src/img/members/2.png`),
              require(`/../src/img/members/3.png`),
              require(`/../src/img/members/4.png`),
              require(`/../src/img/members/5.png`),
              require(`/../src/img/members/6.png`),
              require(`/../src/img/members/7.png`)
            ])
            .map((el, il) =>
              <Avatar class={[
                'elevation-12 mt-6 mb-8',
                il && 'ml-4'
              ]}
                color='whitesmoke'
                size={
                  window.innerWidth / 8
                }
              >
                <Img class=''
                  src={el}
                  aspect-ratio={1}
                />
              </Avatar>
            )
          }
          </Div>
          <Div class='grey lighten-3 py-8'
            cols={12}
            align='center'
          >
            <Row class=''
              align='center'
            >
              <Col class=''
                cols={12}
                tag='h2'
                align='center'
              >WHAT WE DO
              </Col>
              <Col class=''
                cols={5.5}
              />
              <Col class='double-divider-col'
                cols={1}
                align='center'
              >
                <Divider class='ttl-divider'/>
                <Divider class='ttl-divider'/>
              </Col>
              <Col class=''
                cols={5.5}
              />
              <Col cols={12}/>
              <Row class='px-8'>
              {
                [
                  1,2,3
                ].map(el => (
              <Col class='px-4'
                cols={12}
                md={4}
              >
                <Div class=''>
                  <Card class=''
                  >
                    <ThemeProvider class=''>
                      <Div class='py-4'>
                        <Avatar class=''
                          color='#a72028'
                          size='88'
                        >
                          <Icon class=''
                            large
                          >mdi-account-group
                          </Icon>
                        </Avatar>
                      </Div>
                    </ThemeProvider>
                    <CardTitle class='justify-center'
                      tag='h3'
                    >COMMUNITY
                    </CardTitle>
                    <CardText class=''
                      style='text-align: left;'
                    >Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam
                    </CardText>
                  </Card>
                </Div>
              </Col>
                ))
              }
              </Row>
            </Row>
          </Div>
          <Card class=''>
          </Card>
          <Card class=''>
          </Card>
          <Div class=''>
            <Parallax class=''
              height={
                window.innerHeight - APPBAR_HEIGHT
              }
              // src='https://cdn.vuetifyjs.com/images/parallax/material.jpg'
              src={require('./res/blackboard.jpg')}
              style='
              margins-bottom: -10em;
              '
            >
              <Container class=''
                height={
                  window.innerHeight - APPBAR_HEIGHT
                }
              >
                <Row class='pt--8'
                  align="center"
                  justify="center"
                  style='
                  text-shadow: 0 2px 4px black;
                  '
                >{
                  ([{
                    key: 'Teachers',
                    result: '5W+'
                  }, {
                    key: 'Resource',
                    result: '1W+'
                  }, {
                    key: 'Topics',
                    result: '3K+'
                  }]).map((el)=> {
                    return (
                      <Col class='display-3 font-weight-black pt--12'
                        cols={12}
                        md={4}
                        align='center'
                        key={el.key}
                      >{el.result}
                        <Col class='display-1 font-weight-normal'
                          cols={12}
                        >{el.key}</Col>
                      </Col>
                    )
                  })
                }
                </Row>
              </Container>
            </Parallax>
          </Div>
          <Div class=''
            cols={12}
            align='center'
          >
            <Row class=''
              align='center'
            >
              <Col class=''
                cols={12}
                tag='h2'
                align='center'
              >SEEN BEFORE
              </Col>
              <Col class=''
                cols={5.5}
              />
              <Col class='double-divider-col'
                cols={1}
                align='center'
              >
                <Divider class='ttl-divider'/>
                <Divider class='ttl-divider'/>
              </Col>
              <Col class=''
                cols={5.5}
              />
              <Col cols={12}>
                <Div class=''>
                  <Row class='px-4'
                  >{
                    ([
                      {
                        title: '活动一',
                        content: '活动描述 Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.',
                        banner: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'
                      }
                      ,{
                        title: '活动二',
                        content: '活动描述 Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.',
                        banner: 'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'
                      }
                      ,{
                        title: '活动三',
                        content: '活动描述 Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
                        banner: 'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80'
                      }
                    ]).map((el) => {
                      return (
                        <Col class=''
                          cols={12}
                          align='left'
                          md={4}
                        >
                          <Img class=''
                            src={el.banner}
                            height='200'
                          />
                          <Div class='my-2'
                            tag='h3'
                          >{el.title}
                          </Div>
                          <Div class='font-weight-light mb-5'
                          >{el.content}
                          </Div>
                          <Button class=''
                          >Learn More
                          </Button>
                        </Col>
                      )
                    })
                  }
                  </Row>
                </Div>
              </Col>
            </Row>
          </Div>
          <Img class=''/>
          <Button class=''/>
          <Img class=''/>
          <Button class=''/>
          <Img class=''/>
          <Button class=''/>
        </Main>
        <Footer class=''
          height={APPBAR_HEIGHT}
        >@ {(new Date()).getFullYear()} - Made with ❤️ &nbsp;by Xuedong.com
        </Footer>
      </App>
    }
  }
</script>

<style lang="less">
  .double-divider-col .ttl-divider {
    margin-top: -.6em;
  }
</style>
