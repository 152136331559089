// styles:
import './index.less'

// libs:
import Vue from 'vue'

// components:
import Page from './index.vue'

const vm= new Vue({
  // extends: Page
  render () {
    return <Page class='' />
  }
})
.$mount('main')

export default Page
